import { loadingController, toastController  } from '@ionic/vue';
import { checkmarkCircleOutline, warningOutline,closeCircleOutline } from 'ionicons/icons';
import moment from 'moment';
import axios from 'axios';


export default function tools() {    


    const toast = async (message:string, color:'success'|'warning'|'danger',duration=2500) =>{
        let _color = 'succcess' // por defecto
        switch(color){
            case 'success': _color = checkmarkCircleOutline; break;
            case 'warning': _color = warningOutline; break;
            case 'danger': _color = closeCircleOutline; break;
        }
        const _toast = await toastController.create({
            message: message,
            duration: duration,
            position: 'top',
            color: color,
            icon: _color,
          });
  
          await _toast.present();
    }

    const delay = async (milliseconds:number)=> {
        return new Promise(resolve => {
          setTimeout(resolve, milliseconds);
        });
  }

     /*
   * Devuelve el nombre de un usiario contemplando que la primera palabra es el nombre
   */
     const getShortName = (name:string):string => { 
      if(name === '' || name === null || name === undefined ) return name
      //si no tiene espacios, devolvemos el nombre completo
      if(!name.includes(' ')) return name
      const names = name.split(' ')
      if(names.length > 1)
        return names[0]
      else
        return name
      }

   const getImageClase = (src:string) : string => {
      if( (''+src).includes('a')) 
        return src
      else
        return process.env.VUE_APP_MEDIA_URL + src
      
   }

   const getPathImage = (src:string) : string => {
      if( (''+src).includes('a')) 
        return src
      else
        return process.env.VUE_APP_MEDIA_URL + src
      
   }

   
   const showLoading = async (msj:string) => {
    const loading = await loadingController.create({
      message: msj,
    });
    
    loading.present();
  }

   const getYtURLVideo = (url:string)=>{
      if(url.includes('youtube'))
          return url.split('v=')[1]
      else if(url.includes('youtu.be'))
          return url.split('.be/')[1]
      else
        return false
   }

   const number2day = (numberDay:number) : string => {
    switch(numberDay){
      case 1: return 'Lunes';
      case 2: return 'Martes';
      case 3: return 'Miercoles';
      case 4: return 'Jueves';
      case 5: return 'Viernes';
      case 6: return 'Sábado';
      case 7: return 'Domingo';
      default: return 'undefined';
    }
   }

   const relativeDate = (myDate:Date,conHora=true) : string =>{
      if(conHora)
          return moment(myDate).fromNow()
      else{
        if( moment(myDate).format('YMD') == moment().format('YMD') ) return 'hoy';
        else if( moment(myDate).format('YMD') == moment().add(1,'days').format('YMD') ) return 'mañana';
        else if( moment(myDate).format('YMD') == moment().add(2,'days').format('YMD') ) return 'pasado mañana';
        else if( moment(myDate).format('YMD') == moment().add(-1,'days').format('YMD') ) return 'ayer';
        else if( moment(myDate).format('YMD') == moment().add(-2,'days').format('YMD') ) return 'antes de ayer';
        else if( moment(myDate).diff(moment(),'days' ) < 7) return 'este ' + moment(myDate).format('dddd');
        else if( moment(myDate).diff(moment(),'days' ) >= 7) return ' el próximo ' + moment(myDate).format('dddd') + ' ' + moment(myDate).format('d')
        else return moment(myDate).format('dddd d') + ' de ' + moment(myDate).format('mmmm')
      }
   }

   const setLog = (event:string,level?: 'warning' | 'info' | 'error' | 'debug') => {
        try {
          axios.post('log/set',{
                                                  event:event,
                                                  level: level === undefined ? 'info' : level
                                              })
      } catch (error) {
          console.log('No se pudo guardar el log en el servidor para el evento -' + event + '-')
      }
   }

    return {
        toast,delay,getImageClase,number2day,relativeDate,getYtURLVideo,setLog,getPathImage,showLoading,getShortName
    }
  }